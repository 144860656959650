 function getOperatingSystem() {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes('mac')) return 'Mac';
    if (platform.includes('win')) return 'Windows';
    // 添加其他操作系统的检测
    return 'Unknown';
  }
function ImportFont() {
    if(getOperatingSystem() !== 'Mac'){
        // 加载字体
        const font = new window.FontFace('SourceHanSansCN-Regular', 'url(https://cdn.wendugroup.com/web-assets/fonts/SourceHanSansCN-Regular.otf)');
        const fontMedium = new window.FontFace('SourceHanSansCN-Medium', 'url(https://cdn.wendugroup.com/web-assets/fonts/SourceHanSansCN-Medium.otf)');
        font.load().then((loadedFont: any) => {
            (document as any).fonts?.add(loadedFont);
        })
        fontMedium.load().then((loadedFont: any) => {
            (document as any).fonts?.add(loadedFont);
        })
    }
}
export default ImportFont
